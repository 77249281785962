import gql from 'graphql-tag'
import { CommunityTextPostClipFragment } from 'graphql/fragments/CommunityPostClip'
import { PostClipFragment } from '../fragments/PostClip'
import { TopicFragment } from '../fragments/TopicFragment'

export const TOPIC_SLUG_QUERY = gql`
  query TopicBySlug($slug: String!, $after: String) {
    topic: topicBySlug(slug: $slug) {
      ...TopicFragment
      combinedContentConnection(first: 10, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
        }
        nodes {
          __typename
          ... on Post {
            ...PostClipFragment
          }
          ... on CommunityTextPost {
            ...CommunityTextPostClipFragment
          }
        }
      }
    }
  }
  ${PostClipFragment}
  ${CommunityTextPostClipFragment}
  ${TopicFragment}
`
