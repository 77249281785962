import { NOTIFICATIONS_QUERY } from 'graphql/queries/NotificationQuery'
import { NotificationQuery } from 'types/generated/NotificationQuery'
import { useInfiniteQuery } from './useInfiniteQuery'

export const useNotifications = () => {
  const {
    data,
    loading,
    error,
    hasNextPage,
    fetchMore,
  } = useInfiniteQuery<NotificationQuery>(NOTIFICATIONS_QUERY, {
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
    connectionKey: 'notificationConnection',
  })
  return {
    notifications: data?.notificationConnection?.nodes || [],
    loading,
    error,
    hasNextPage,
    fetchMore,
  }
}
