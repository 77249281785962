import { DismissibleModal } from 'components/DismissibleModal'
import Link from 'components/Link'
import React, { ChangeEvent, FormEvent, useState } from 'react'
import { CommentData } from 'types/generated/CommentData'
import style from './index.module.css'
import { useUpdateUsername } from '../shared/useUpdateUsername'

interface Props {
  onDismiss: () => void
  comment?: CommentData
}
export const CompleteUsernameModal = ({ onDismiss }: Props) => {
  const [username, setUsername] = useState('')
  const { updateUsername } = useUpdateUsername()
  const [status, setStatus] = useState('')

  const [submissionErrorMsg, setSubmissionErrorMsg] = useState('')
  const [usernameErrorMsg, setUsernameErrorMsg] = useState('')

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!username) {
      return setUsernameErrorMsg('Username cannot be blank')
    } else {
      usernameErrorMsg && setUsernameErrorMsg('')
    }
    if (!/^(\w){4,20}$/.test(username)) {
      setUsernameErrorMsg(
        'Username can only contain characters a-Z, 0-9, or an _ and must be 4-20 characters long'
      )
    } else {
      usernameErrorMsg && setUsernameErrorMsg('')
    }
    if (usernameErrorMsg) {
      return
    }

    try {
      setStatus('Updating Username')
      await updateUsername(username.trim())
    } catch (e) {
      setSubmissionErrorMsg((e as Error).message)
      setStatus('An error has occured')
    }
    setStatus('Username Updated')
    onDismiss()
  }

  return (
    <DismissibleModal onDismiss={onDismiss}>
      <div className={style.usernameModal}>
        <div>
          <h3 className={style.modalHeader}>Hold that thought!</h3>
          <div className={style.modalCopy}>
            Before you can participate in our community you'll need a username.
          </div>
          <div className={style.modalCopy}>
            You can't change your username once you've picked it. So make it
            count!
          </div>
        </div>
        <div className={style.usernameModalForm}>
          <form className={'form'} onSubmit={handleSubmit}>
            <div className={'errorHolder'}>
              {submissionErrorMsg && (
                <span className={'errorMessage'}>{submissionErrorMsg}</span>
              )}
              {usernameErrorMsg && (
                <span className={'errorMessage'}>{usernameErrorMsg}</span>
              )}
            </div>
            <label className={'formLabel'}>
              Username
              <input
                type="username"
                value={username}
                maxLength={20}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setUsername(e.target.value)
                }}
                placeholder="Enter username"
              />
              <span style={username.length >= 15 ? { color: 'red' } : {}}>
                {username.length}/20
              </span>
            </label>
            <div className={'formSubmits'}>
              <button
                aria-label="Create Username"
                className={'formSubmit'}
                type="submit"
              >
                {status ? <span>{status}</span> : <span>Save</span>}
              </button>
            </div>
          </form>
          <div>
            You can also add a bio on your{' '}
            <Link href="/settings/profile">profile settings page.</Link>
          </div>
        </div>
      </div>
    </DismissibleModal>
  )
}
