import gql from 'graphql-tag'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  UpdateCommPost,
  UpdateCommPostVariables,
} from 'types/generated/UpdateCommPost'
import { useCommPostPermissions } from './useCommPostPermissions'
import { CommunityTextPostClipFragment } from 'graphql/fragments/CommunityPostClip'

const UPDATE_COMM_POST_MUTATION = gql`
  mutation UpdateCommPost($input: EditCommunityPostInput!) {
    editCommunityPost(input: $input) {
      communityPost {
        ...CommunityTextPostClipFragment
      }
    }
  }
  ${CommunityTextPostClipFragment}
`

interface UpdateCommPostProps {
  commPost: any
  prosedoc: any
}

export const useUpdateCommPost = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { canPost } = useCommPostPermissions()
  const [updateCommPost] = useMutation<UpdateCommPost, UpdateCommPostVariables>(
    UPDATE_COMM_POST_MUTATION
  )

  return {
    updateCommPost: ({ commPost, prosedoc }: UpdateCommPostProps) => {
      if (!canPost || isSubmitting) {
        return null
      }
      setIsSubmitting(true)

      return updateCommPost({
        variables: { input: { commPostId: commPost.id, prosedoc } },
        optimisticResponse: {
          editCommunityPost: {
            __typename: 'EditCommunityPostOutput',
            communityPost: {
              ...commPost,
              prosedoc,
            },
          },
        },
      }).finally(() => setIsSubmitting(false))
    },
  }
}
