import { Prosedoc } from './types'

export function countChars(prosedoc: Prosedoc): number {
  const {
    doc: { content: toplevelContents },
  } = prosedoc
  let length = toplevelContents.length - 1

  for (const toplevelContent of toplevelContents) {
    const { content } = toplevelContent
    if (!content) {
      continue
    }
    for (const leafContent of content) {
      if (leafContent.type === 'text') {
        length += leafContent.text.length
      }
    }
  }

  return length
}
