import React from 'react'

export const SearchIcon = ({ active }: { active: boolean }) => {
  const stroke = active ? '#e2856e' : '#22223b'
  const strokeWidth = active ? '2' : '1.5'
  return (
    <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="10.615"
        cy="10.615"
        r="9.865"
        fill="transparent"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <path
        d="M17.691 17.692 24.18 23"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
