import React from 'react'

export const ProfileIcon = () => (
  <svg fill="none" height="43" width="43" xmlns="http://www.w3.org/2000/svg">
    <g
      stroke="#e2856e"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M13.617 16.77a7.885 7.885 0 1 0 15.77 0 7.885 7.885 0 0 0-15.77 0zM8.031 36.954a15.769 15.769 0 0 1 26.94 0" />
      <path d="M1 21.5a20.5 20.5 0 1 0 41 0 20.5 20.5 0 0 0-41 0z" />
    </g>
  </svg>
)
