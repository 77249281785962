import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { splitPath } from 'lib/urls'
import { useCurrentUser } from './useCurrentUser'
import { useGlobalModalContext } from './useGlobalModalContext'
import { useCurrentUserNotificationCount } from './useCurrentUserNotificationCount'

type ActiveIcon = 'home' | 'discover' | 'favorites' | null

interface NavButtonState {
  activeIcon: ActiveIcon
  isNotificationsActive: boolean
  isEditorActive: boolean
  hasNewNotifications: boolean
}

const extractActiveIconFromPath = (path: string): ActiveIcon => {
  const [firstPathPart] = splitPath(path)
  if (firstPathPart === 'discover') {
    return 'discover'
  }
  if (firstPathPart === 'favorites') {
    return 'favorites'
  }
  if (!firstPathPart) {
    return 'home'
  }
  return null
}

export function useNavButtonState() {
  const router = useRouter()
  const { user } = useCurrentUser()
  const { notificationCount } = useCurrentUserNotificationCount()
  const { toggleModalOpen } = useGlobalModalContext()

  const [
    { activeIcon, isNotificationsActive, hasNewNotifications, isEditorActive },
    setState,
  ] = useState<NavButtonState>({
    activeIcon: extractActiveIconFromPath(router.asPath),
    isNotificationsActive: false,
    isEditorActive: false,
    hasNewNotifications: !!notificationCount,
  })

  const openNotifications = () =>
    setState((prevState) => ({
      ...prevState,
      isNotificationsActive: true,
    }))

  const closeNotifications = () =>
    setState((prevState) => ({
      ...prevState,
      hasNewNotifications: false,
      isNotificationsActive: false,
      activeIcon: extractActiveIconFromPath(router.asPath),
    }))

  const toggleEditor = () =>
    setState((prevState) => ({
      ...prevState,
      activeIcon: extractActiveIconFromPath(router.asPath),
      isEditorActive: !isEditorActive,
    }))

  useEffect(() => {
    if (notificationCount && !isNotificationsActive) {
      setState((prevState) => ({
        ...prevState,
        hasNewNotifications: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationCount])

  useEffect(
    () =>
      setState((prevState) => ({
        ...prevState,
        activeIcon: extractActiveIconFromPath(router.asPath),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.asPath]
  )
  const isFauxRouteActive = isNotificationsActive || isEditorActive
  return {
    isHomeActive: !isFauxRouteActive && activeIcon === 'home',
    isDiscoverActive: !isFauxRouteActive && activeIcon === 'discover',
    isFavoritesActive: !isFauxRouteActive && activeIcon === 'favorites',
    isNotificationsActive,
    isEditorActive,
    hasNewNotifications: !isNotificationsActive && hasNewNotifications,
    toggleNotLoggedInModal: toggleModalOpen,
    openNotifications,
    closeNotifications,
    toggleEditor,
    user,
  }
}
