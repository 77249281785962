import { FetchPolicy, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { CurrentUserQuery } from 'types/generated/CurrentUserQuery'
import * as Sentry from '@sentry/node'
import { CURRENT_USER_QUERY } from 'graphql/queries/CurrentUserQuery'

interface Props {
  fetchPolicy: FetchPolicy
}
export const useCurrentUser = (props?: Props) => {
  const { data, loading } = useQuery<CurrentUserQuery>(CURRENT_USER_QUERY, {
    fetchPolicy: props?.fetchPolicy,
  })

  useEffect(() => {
    if (!data?.currentUser) {
      return
    }
    //type error if i pass it without fixing username type
    Sentry.setUser({
      ...data.currentUser,
      username: data.currentUser.username || undefined,
    })
  }, [data])

  return { user: data?.currentUser, loading }
}
