import gql from 'graphql-tag'
import { PrivateUserFragment } from '../fragments/UserFragment'

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    currentUser {
      ...PrivateUserFragment
    }
  }
  ${PrivateUserFragment}
`
