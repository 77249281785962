import cn from 'classnames'
import style from './index.module.css'
import Link from '../Link'

export default function Footer() {
  return (
    <footer className={style.footer}>
      <div className={style.contents}>
        <div className={style.sections}>
          <Link href="/" className={style.logo}>
            Dipp
          </Link>
          <Link href="/privacy-policy" className={style.section}>
            Privacy
          </Link>
          <Link href="/terms-of-use" className={style.section}>
            Terms
          </Link>
        </div>

        <div className={cn(style.social, 'font-heading')}>
          <div>Follow Us</div>
          <a
            className={style.socialLink}
            href="https://www.facebook.com/thedippdotcom"
          >
            Facebook
          </a>
          <a className={style.socialLink} href="https://twitter.com/thedipp">
            Twitter
          </a>
          <a
            className={style.socialLink}
            href="https://www.instagram.com/thedipp/"
          >
            Instagram
          </a>
          <div className={style.copy}>2020 The Dipp. All rights reserved.</div>
        </div>
      </div>
    </footer>
  )
}
