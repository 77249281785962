import gql from 'graphql-tag'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  CreateCommPost,
  CreateCommPostVariables,
} from 'types/generated/CreateCommPost'
import { useCommPostPermissions } from './useCommPostPermissions'

const CREATE_COMM_POST_MUTATION = gql`
  mutation CreateCommPost($input: CreateCommPostInput!) {
    createCommunityPost(input: $input) {
      communityPost {
        __typename
        path
        publicId
        title
        author {
          publicId
          username
        }
      }
    }
  }
`
interface CreateCommPostProps {
  input: CreateCommPostVariables['input']
}

export const useCreateCommPost = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { canPost } = useCommPostPermissions()
  const [createCommPost] = useMutation<CreateCommPost, CreateCommPostVariables>(
    CREATE_COMM_POST_MUTATION
  )

  return {
    createCommPost: ({
      topicId,
      title,
      prosedoc,
    }: CreateCommPostProps['input']) => {
      if (!canPost || isSubmitting) {
        return null
      }
      setIsSubmitting(true)

      return createCommPost({
        variables: { input: { topicId, title, prosedoc } },
      }).finally(() => setIsSubmitting(false))
    },
  }
}
