import style from './index.module.css'
import cn from 'classnames'
import { useState } from 'react'
import { NotificationIcon } from './NotificationIcon'
import Link from 'components/Link'
import { timeAgo } from 'lib/timeAgo'
import { NotificationQuery_notificationConnection_nodes } from 'types/generated/NotificationQuery'

export function Notification({
  notification,
  hideNotification,
}: {
  hideNotification: (id: string) => void
  notification: NotificationQuery_notificationConnection_nodes
}) {
  const {
    __typename,
    id,
    comment: {
      author: { username },
      path: linkPath,
      preview,
      hasAncestors,
    },
    createdAt,
    status,
  } = notification

  const [showing, setShowing] = useState(status !== 'deleted')

  function hideButtonClicker(id: string) {
    hideNotification(id)
    setShowing(false)
  }
  const initial = (username && username[0]) || 'D'

  return (
    <div className={cn(showing ? style.wrapper : style.hiddenWrapper)}>
      <NotificationIcon initials={initial} />
      <div className={style.contentContainer}>
        <div className={style.headlineContainer}>
          {' '}
          <div className={style.headline}>
            {username}{' '}
            {__typename === 'CommunityPostReplyNotification' && !hasAncestors
              ? 'commented on your post.'
              : 'responded to your comment.'}
          </div>{' '}
          <button
            aria-label="Hide"
            onClick={() => hideButtonClicker(id)}
            className={style.closeButton}
          >
            hide
          </button>
        </div>

        <span className={style.content}>
          <Link
            rel="nofollow, ugc"
            className={style.commentLink}
            href={linkPath}
          >
            "{preview}"{' '}
          </Link>
        </span>

        <div className={style.timeStamp}>
          {status === 'unread' && (
            <span className={style.newAlert}> [New] </span>
          )}
          {timeAgo(createdAt)}{' '}
        </div>
      </div>
    </div>
  )
}

Notification.displayName = 'Notification'
