import { useContext } from 'react'
import {
  CantPostModalContextProps,
  CantPostModalContext,
} from './CommunityPostInput'

export function useCantPostModalContext() {
  const context = useContext(CantPostModalContext)
  if (context === undefined) {
    throw new Error(
      `useModalOpenContext must be used within a ModalOpenContextProvider`
    )
  }
  return context as CantPostModalContextProps
}
