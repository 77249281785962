import gql from 'graphql-tag'

export const UPDATE_NOTIFICATION_STATUS = gql`
  mutation UpdateNotificationStatus($input: UpdateNotificationStatusInput!) {
    updateNotificationStatus(input: $input) {
      count
      updatedNotifications {
        id
        updatedAt
        status
      }
    }
  }
`
