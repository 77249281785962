import { useEffect, useState } from 'reactn'
import throttle from 'lodash.throttle'
import cn from 'classnames'
import style from './index.module.css'
import Link from '../Link'

import { NavButtons } from './NavButtons'
import { UserProfileContainer } from './UserProfileContainer'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useUserSettingsCloseRedirect } from 'hooks/useUserSettingsCloseRedirect'
import React from 'react'

interface ScrollCBArgs {
  previousScrollTop: number
  currentScrollTop: number
}

const MINIMUM_SCROLL = 10
const TIMEOUT_DELAY = 0

function useDocumentScrollThrottled(cb: (a: ScrollCBArgs) => void) {
  const [, setScrollPosition] = useState(0)

  let previousScrollTop = 0

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } =
      document.documentElement || document.body

    setScrollPosition((previousPosition) => {
      previousScrollTop = previousPosition
      return currentScrollTop
    })

    cb({ previousScrollTop, currentScrollTop })
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250)

  useEffect(() => {
    window.addEventListener('scroll', handleDocumentScrollThrottled)

    return () =>
      window.removeEventListener('scroll', handleDocumentScrollThrottled)
    // TODO: needs the code above moved into the useEffect, and then the previousScrollTop
    // variable needs to be moved into a useRef
    //eslint-disable-next-line
  }, [])
}

export const Header = React.memo(function Header() {
  const [shouldHideHeader, setShouldHideHeader] = useState(false)
  const [shouldShowShadow, setShouldShowShadow] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useCurrentUser()
  const { storeRedirectUrl } = useUserSettingsCloseRedirect()

  useDocumentScrollThrottled(({ previousScrollTop, currentScrollTop }) => {
    const isScrolledDown = previousScrollTop < currentScrollTop
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

    setShouldShowShadow(currentScrollTop > 2)

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled)
    }, TIMEOUT_DELAY)
  })

  function toggle() {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div>
        <label htmlFor="nav">
          <span />
        </label>
        <input
          id="nav"
          className={style.checkbox}
          type="checkbox"
          name="nav"
          aria-label="nav"
          checked={isOpen}
          onClick={toggle}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={function noop() {}}
        />

        <header
          className={cn(style.header, {
            hidden: shouldHideHeader,
            shadow: shouldShowShadow,
          })}
        >
          <div className={style.constrain}>
            <div className={style.left}>
              <Link href="/" className={style.logo}>
                Dipp
              </Link>

              <NavButtons />
            </div>
            <div className={style.right}>
              <div className={style.userNameContainer}>
                <span className={style.userName}>
                  {user?.firstName} {user?.lastName}
                </span>
              </div>

              {user ? (
                <UserProfileContainer />
              ) : (
                <div className={style.buttonsContainer}>
                  <Link
                    className={cn('button', style.signUp)}
                    href="/subscribe"
                  >
                    Sign Up
                  </Link>
                  <Link
                    className={'button secondary'}
                    href={'/sign-in'}
                    aria-label="Sign In"
                    onClick={() => storeRedirectUrl()}
                  >
                    Sign In
                  </Link>
                </div>
              )}
            </div>
          </div>
        </header>
      </div>
    </>
  )
})
