import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import { TOPIC_SLUG_QUERY } from 'graphql/queries/TopicBySlugQuery'
import { TopicBySlug, TopicBySlugVariables } from 'types/generated/TopicBySlug'

export function useTopicPageQuery() {
  const router = useRouter()
  const { topicSlug } = router.query as { topicSlug: string }

  if (!topicSlug || typeof topicSlug !== 'string') {
    return {
      error: new Error(`useTopicPageQuery must be used within a topic page`),
      data: null,
      loading: false,
      fetchMore: null,
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { error, data, fetchMore, loading } = useQuery<
    TopicBySlug,
    TopicBySlugVariables
  >(TOPIC_SLUG_QUERY, {
    variables: { slug: topicSlug },
  })

  return { error, data, fetchMore, loading }
}
