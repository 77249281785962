import { useRouter } from 'next/router'
import { isBrowser } from 'lib/browser'
import { writeToLocalStorage, readFromLocalStorage } from 'lib/localStorage'

const PATH_HISTORY_KEY = 'DIPP_REDIRECT_URL'

export function useUserSettingsCloseRedirect() {
  const router = useRouter()

  function storeRedirectUrl() {
    if (isBrowser() && location.pathname.indexOf('/settings') !== 0) {
      return writeToLocalStorage(PATH_HISTORY_KEY, location.pathname)
    }
  }

  function redirect() {
    const redirectPath = readFromLocalStorage(PATH_HISTORY_KEY) || '/'
    return router.push(redirectPath)
  }

  return { storeRedirectUrl, redirect }
}
