import gql from 'graphql-tag'
import { PageInfoFragment } from 'graphql/fragments/PageInfo'
import { UserAuthorFragment } from 'graphql/fragments/UserFragment'

export const NOTIFICATIONS_QUERY = gql`
  query NotificationQuery($after: String, $first: Int = 10) {
    notificationConnection(first: $first, after: $after) {
      pageInfo {
        ...PageInfoFragment
      }
      nodes {
        __typename
        ... on CommentReplyNotification {
          comment {
            id
            publicId
            preview
            path
            hasAncestors
            author {
              ...UserAuthorFragment
            }
          }
        }
        ... on CommunityPostReplyNotification {
          comment {
            id
            publicId
            preview
            path
            hasAncestors
            author {
              ...UserAuthorFragment
            }
          }
        }
        id
        type
        createdAt
        status
      }
    }
  }
  ${PageInfoFragment}
  ${UserAuthorFragment}
`
