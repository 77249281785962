import * as Sentry from '@sentry/node'
import style from './index.module.css'

interface ErrorFallbackProps {
  error: Error
}

export function ErrorFallback({ error }: ErrorFallbackProps) {
  Sentry.captureException(error)
  return (
    <div role="alert" className={style.error}>
      <head>
        <title>Something went wrong</title>
      </head>
      <div className={style.title}>Oh Dipp!</div>
      <div className={error.message}>Sorry, something went wrong.</div>
      <button
        className={style.reloadButton}
        onClick={() => window.location.reload()}
      >
        Try again?
      </button>
    </div>
  )
}
