import React from 'react'

function X({ fill, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m20.713 17.209-6.83-6.856 6.846-6.767a.723.723 0 0 0 0-1.02L18.782.608a.716.716 0 0 0-.51-.208.739.739 0 0 0-.51.208l-6.815 6.747L4.123.613a.716.716 0 0 0-.51-.208.739.739 0 0 0-.51.208L1.16 2.571a.723.723 0 0 0 0 1.02l6.845 6.767-6.824 6.851a.721.721 0 0 0-.214.51.7.7 0 0 0 .214.51l1.947 1.958a.718.718 0 0 0 1.02 0l6.798-6.83 6.804 6.824a.718.718 0 0 0 1.02 0l1.947-1.957a.721.721 0 0 0 .214-.51.747.747 0 0 0-.219-.505z" />
    </svg>
  )
}

export default X
