import React from 'react'

export const HomeIcon = ({ active }: { active: boolean | undefined }) => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.6 9.6v13.2h16.8V9.6"
      stroke={active ? '#e2856e' : '#22223b'}
      strokeWidth={active ? '2' : '1.5'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.2 12 12 1.2 22.8 12M12 22.8v-7.2"
      stroke={active ? '#e2856e' : '#22223b'}
      strokeWidth={active ? '2' : '1.5'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
