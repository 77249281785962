import { useMemo } from 'react'
import orderBy from 'lodash.orderby'
import {
  ApolloClient,
  InMemoryCache,
  PossibleTypesMap,
  HttpLink,
  from as chainLinks,
} from '@apollo/client'
import { onError as createErrorLink } from '@apollo/client/link/error'
import fragmentTypes from '../types/generated/fragmentTypes.json'
import { isBrowser } from './browser'

let apolloClient: ApolloClient<InMemoryCache>

const sortCommentsByScore = (comments: any[]) =>
  orderBy(comments, ['score', 'createdAt'], ['desc', 'asc'])

export function createApolloClient({
  headers,
}: { headers?: Record<string, string | undefined> } = {}) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: chainLinks([
      createErrorLink(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, path }) => {
            // eslint-disable-next-line no-console
            console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`)
          })
        }
        if (networkError) {
          // eslint-disable-next-line no-console
          console.log(`[Network error]: ${networkError}`)
        }
      }),
      new HttpLink({
        uri: process.env.graphqlApiProxyUri, // TEMPORARY STOP-GAP FOR ipv6 coercion issue on Matt's Machine, // Server URL (must be absolute)
        credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
        headers,
      }),
    ]),
    cache: new InMemoryCache({
      possibleTypes: {
        ...((fragmentTypes as unknown) as PossibleTypesMap),
        BaseUser: ['PublicUser', 'PrivateUser'],
      },
      typePolicies: {
        PublicUser: {
          keyFields: ['publicId'],
        },
        CommunityPost: {
          keyFields: ['publicId'],
        },
        PrivateUser: {
          keyFields: ['publicId'],
          fields: {
            seriesList: {
              merge(_, incoming) {
                return incoming
              },
            },
            topicsList: {
              merge(_, incoming) {
                return incoming
              },
            },
          },
        },
        Query: {
          fields: {
            commentAndReplyConnectionByPath: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['path'],
              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = {}, incoming, { args }) {
                if (args?.before) {
                  return {
                    ...existing,
                    ...incoming,
                    replyConnection: {
                      ...incoming.replyConnection,
                      pageInfo: {
                        startCursor:
                          incoming.replyConnection?.pageInfo.startCursor,
                        endCursor: existing.replyConnection?.pageInfo.endCursor,
                        hasNextPage:
                          existing.replyConnection?.pageInfo.hasNextPage,
                      },
                      nodes: [
                        ...sortCommentsByScore(incoming.replyConnection.nodes),
                        ...(existing.replyConnection?.nodes || []),
                      ],
                    },
                  }
                }
                if (args?.after) {
                  return {
                    ...existing,
                    ...incoming,
                    replyConnection: {
                      ...incoming.replyConnection,
                      pageInfo: {
                        startCursor:
                          existing.replyConnection?.pageInfo.startCursor,
                        endCursor: incoming.replyConnection?.pageInfo.endCursor,
                        hasNextPage:
                          incoming.replyConnection?.pageInfo.hasNextPage,
                      },
                      nodes: [
                        ...(existing.replyConnection?.nodes || []),
                        ...sortCommentsByScore(incoming.replyConnection.nodes),
                      ],
                    },
                  }
                }
                return incoming
              },
            },
          },
        },
      },
    }),
  })
}

export function getApolloClient({
  headers,
  initialState,
}: { headers?: Record<string, string | undefined>; initialState?: any } = {}) {
  const _apolloClient = apolloClient ?? createApolloClient({ headers })
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  // For SSG and SSR always create a new Apollo Client
  if (!isBrowser()) {
    return _apolloClient
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient
  }
  return _apolloClient
}

export function useApollo(initialState?: { [k: string]: any }) {
  // by adding initialState to the useMemo, this is recreating the apollo client on every page change
  // cached data is lost. i removed it and now things work as expected
  //eslint-disable-next-line
  const store = useMemo(() => getApolloClient({ initialState }), [])
  return store
}
