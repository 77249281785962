import gql from 'graphql-tag'
import { TopicFragment } from 'graphql/fragments/TopicFragment'

export const TOPIC_SEARCH_QUERY = gql`
  query TopicSearchQuery($query: String!, $after: String) {
    topicSearchConnection(query: $query, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      nodes {
        ...TopicFragment
      }
    }
  }
  ${TopicFragment}
`
