import { useCurrentUser } from 'hooks/useCurrentUser'

export const useCommPostPermissions = () => {
  const { user } = useCurrentUser()

  return {
    canPost: user?.isSubscribed,
    canVote: !!user,
  }
}
