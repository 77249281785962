import { useEffect, useRef } from 'react'

export function useDebouncer(func: (...args: any[]) => any, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }, [])

  return function debouncedFuncExecutor(...args: any[]) {
    const later = () => {
      func(...args)
      timeoutRef.current = null
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(later, delay)
  }
}
