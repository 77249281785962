import style from './index.module.css'

export function NotificationIcon({ initials }: { initials: string }) {
  return (
    <div className={style.wrapper}>
      <div className={style.initials}>{initials}</div>
    </div>
  )
}

NotificationIcon.displayName = 'NotificationIcon'
