import { useState } from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import {
  CommunityPostEditor,
  CommunityPostEditorProps,
} from 'components/CommPost/CommunityPostEditor'
import { CompleteUsernameModal } from 'components/CommentSystem/CommunityPostComment/CompleteUsernameModal'
import { useCommPostPermissions } from 'components/CommPost/useCommPostPermissions'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { CantPostModal } from 'components/CommPost/CantPostModal'
import X from 'components/IconSvgs/X'
import style from './index.module.css'
import { CommunityPostByPublicId_commPost } from 'types/generated/CommunityPostByPublicId'
import { CommunityTextPostClipFragment } from 'types/generated/CommunityTextPostClipFragment'

interface EditorActions {
  create?: CommunityPostEditorProps['create']
  update?: CommunityPostEditorProps['update']
}
export function CommPostEditorView({
  showing,
  close,
  commPost,
}: {
  showing: boolean
  close: () => void
  commPost?: CommunityPostByPublicId_commPost | CommunityTextPostClipFragment
}) {
  const { user } = useCurrentUser()
  const [serverResponseError, setServerResponseError] = useState('')
  const permissions = useCommPostPermissions()

  if (!permissions.canPost) {
    return <CantPostModal onDismiss={() => close()} />
  }

  if (user && !user.username) {
    return <CompleteUsernameModal onDismiss={() => close()} />
  }

  let editorTitle = 'Make a post'
  let editorAction: EditorActions = {
    create: {
      errorHandler: setServerResponseError,
    },
  }

  if (commPost) {
    editorTitle = 'Edit Your Post'
    editorAction = {
      update: {
        errorHandler: setServerResponseError,
        commPost,
      },
    }
  }

  return (
    <DialogOverlay isOpen={showing} onDismiss={close} className={style.overlay}>
      <DialogContent
        aria-label="community post modal"
        className={style.content}
      >
        <div className={style.header}>
          {editorTitle}
          <button className={style.unstyledButton} onClick={close}>
            {<X fill={'#dd745a'} />}
          </button>
        </div>
        <CommunityPostEditor
          serverResponseError={serverResponseError}
          onClose={close}
          {...editorAction}
        />
      </DialogContent>
    </DialogOverlay>
  )
}

CommPostEditorView.displayName = 'CommPostEditorView'
