import cn from 'classnames'
import React, { ChangeEvent, useEffect, useState } from 'react'
import * as Sentry from '@sentry/node'
import { countChars, Prosedoc } from 'lib/prosemirror'
import { CommunityPostByPublicId_commPost } from 'types/generated/CommunityPostByPublicId'
import { CommunityTextPostClipFragment } from 'types/generated/CommunityTextPostClipFragment'
import { ModalPortal } from 'components/Modal'
import { ProseMirrorEditor } from 'components/ProseMirrorEditor'
import { useCreateCommPost } from 'components/CommPost/useCreateCommPost'
import style from './index.module.css'
import { useRouter } from 'next/router'
import { useDebouncer } from 'hooks/useDebouncer'
import { useLazyQuery } from '@apollo/client'
import { TOPIC_SEARCH_QUERY } from 'graphql/queries/TopicSearchQuery'
import {
  TopicSearchQuery,
  TopicSearchQueryVariables,
  TopicSearchQuery_topicSearchConnection_nodes as Topic,
} from 'types/generated/TopicSearchQuery'
import { emptyCommPostProsedoc } from './emptyProsedoc'
import { TopicDropDownSelection } from './TopicDropDownSelection'
import { ClearingX } from 'components/IconSvgs/ClearingX'
import { useTopicPageQuery } from 'hooks/useTopicPageQuery'
import { useUpdateCommPost } from '../useUpdateCommPost'

const SENT = 'sent'
const SENDING = 'sending'
const FAILED = 'failed'

type SubmissionStatus = typeof SENT | typeof SENDING | typeof FAILED

const StatusMessage = ({
  status,
  onDismiss,
  update,
}: {
  status: SubmissionStatus
  onDismiss: () => void
  update: boolean
}) => {
  if (status === FAILED) {
    return (
      <ModalPortal elementId={'commPostEditorPortal'}>
        <div className={cn(style.commentStatus, style.slideIn)}>
          <div className={style.commentError}>
            <div>Something Went Wrong</div>
            <button onClick={onDismiss}>X</button>
          </div>
        </div>
      </ModalPortal>
    )
  }

  if (update) {
    return (
      <ModalPortal elementId={'commPostEditorPortal'}>
        <div
          className={cn(
            style.commentStatus,
            status === SENDING ? style.slideIn : style.slideOut
          )}
        >
          <div className={style.commentSent}>
            {status === SENDING ? 'Saving Changes...' : 'Updated!'}
          </div>
        </div>
      </ModalPortal>
    )
  }

  return (
    <ModalPortal elementId={'commPostEditorPortal'}>
      <div
        className={cn(
          style.commentStatus,
          status === SENDING ? style.slideIn : style.slideOut
        )}
      >
        <div className={style.commentSent}>
          {status === SENDING ? 'Uploading Post...' : 'Posted!'}
        </div>
      </div>
    </ModalPortal>
  )
}

const TopicDropdownInput = ({
  setCommunity,
  setIsTopicSelected,
  disabled,
  topic,
}: {
  disabled: boolean
  topic?: string
  setIsTopicSelected: (bool: boolean) => void
  setCommunity: (community: string) => void
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [communitySearchInput, setCommunitySearchInput] = useState('')

  const [getTopics, { data }] = useLazyQuery<
    TopicSearchQuery,
    TopicSearchQueryVariables
  >(TOPIC_SEARCH_QUERY)

  const communitySearch = (community: string) => {
    getTopics({ variables: { query: community as string } })
    setIsDropDownOpen(true)
  }

  const communitySearchClear = () => {
    setIsDropDownOpen(false)
  }

  const debouncedCommunitySearch = useDebouncer(communitySearch, 150)

  function communitySearchChangeHandler(value: string) {
    setCommunitySearchInput(value)
    debouncedCommunitySearch(value)
  }

  function selectACommunity(community: Topic) {
    setCommunity(community.id)
    setCommunitySearchInput(community.name)
    communitySearchClear()
    setIsTopicSelected(true)
  }

  return (
    <div className={style.inputContainer}>
      <button
        aria-label="Clear"
        onClick={() => (
          setCommunitySearchInput(''),
          setCommunity(''),
          setIsTopicSelected(false),
          communitySearchClear()
        )}
        className={
          communitySearchInput ? style.clearingX : style.hiddenClearingX
        }
      >
        <ClearingX fill={'#22223b'} stroke={'#22223b'} strokeWidth={'2px'} />
      </button>
      <input
        disabled={disabled}
        placeholder={'Which community will you post to?'}
        className={cn(style.titleInput, {})}
        value={topic ? topic : communitySearchInput}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.value !== '') {
            communitySearchChangeHandler(e.target.value)
          } else {
            communitySearchClear(),
              setCommunitySearchInput(e.target.value || '')
          }
        }}
      />
      {data?.topicSearchConnection?.nodes && isDropDownOpen && (
        <div className={style.topicDropdownContainer}>
          <TopicDropDownSelection
            possibleTopics={data.topicSearchConnection?.nodes}
            setCommunity={selectACommunity}
          />
        </div>
      )}
    </div>
  )
}

const PostTitleInput = ({
  value,
  postTitle,
  setTitle,
  setIsValidTitle,
  hasServerError,
  disabled,
}: {
  value: string
  postTitle: string | undefined
  setTitle: (title: string) => void
  setIsValidTitle: (bool: boolean) => void
  hasServerError: boolean
  disabled: boolean
}) => {
  const [titleErrorMsg, setTitleErrorMsg] = useState('')

  const titleValidator = (title: string) => {
    if (!title) {
      setIsValidTitle(false)
      return setTitleErrorMsg('Title cannot be blank')
    }
    if (title.length < 10) {
      setIsValidTitle(false)
      return setTitleErrorMsg('Title must be have at least 10 characters')
    }
    if (title.length > 300) {
      setIsValidTitle(false)
      return setTitleErrorMsg('Title must be shorter than 300 characters')
    }
    setTitleErrorMsg('')
    return setIsValidTitle(true)
  }

  const validateTitleDebounce = useDebouncer(titleValidator, 250)

  function titleChangeHandler(value: string) {
    setTitle(value)
    validateTitleDebounce(value)
  }

  const hasError = !!titleErrorMsg

  return (
    <div className={style.titleInputContainer}>
      {hasError && (
        <span
          className={cn(hasServerError ? style.hidden : style.titleInputError)}
        >
          {titleErrorMsg}
        </span>
      )}
      <input
        value={postTitle ? postTitle : value}
        disabled={disabled}
        placeholder={'Put your title here'}
        className={cn(style.titleInput, {
          inputError: hasError,
        })}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          titleChangeHandler(e.target.value)
        }}
      />
      {!postTitle && (
        <span
          className={cn(
            style.titleCharCount,
            value && value.length > 300 && style.titleCharCountError
          )}
        >
          {value && value.length ? value.length : 0 / 300}
        </span>
      )}
    </div>
  )
}

interface SubmissionData {
  body: Prosedoc
  title: string
  topicId: string
}
interface CanSubmitState {
  isValidBody: boolean
  isValidTitle: boolean
  isTopicSelected: boolean
}

const PostBodyEditor = ({
  body,
  setIsValidBody,
  setBody,
}: {
  body: Prosedoc
  setBody: (body: Prosedoc) => void
  setIsValidBody: (isValid: boolean) => void
}) => {
  const [prosedocData, setProsedocData] = useState<{
    prosedoc: Prosedoc
    prosedocLength: number
  }>({
    prosedoc: body ? body : emptyCommPostProsedoc,
    prosedocLength: body ? countChars(body) : 0,
  })
  const isPostTooLong = prosedocData.prosedocLength > 10000

  useEffect(() => {
    if (prosedocData.prosedocLength > 10000) {
      return setIsValidBody(false)
    }
  }, [prosedocData.prosedoc, prosedocData.prosedocLength, setIsValidBody])

  return (
    <div className={style.editorContainer}>
      <ProseMirrorEditor
        prosedoc={prosedocData.prosedoc}
        defaultProsedocMetaData={{
          version: '0.0.1',
          type: 'communityPost',
        }}
        placeholder={'Type your thoughts here'}
        onChange={(prosedoc) => {
          setProsedocData({
            prosedoc,
            prosedocLength: countChars(prosedoc),
          })
          setBody(prosedoc)
        }}
      />
      <div className={style.inputControls}>
        <span
          className={cn(style.charCount, isPostTooLong && style.charCountError)}
        >
          {prosedocData.prosedocLength}/10000
        </span>
      </div>
    </div>
  )
}

export interface CommunityPostEditorProps {
  onClose: () => void
  create?: {
    errorHandler: (errorMessage: string) => void
  }
  update?: {
    errorHandler: (errorMessage: string) => void
    commPost: CommunityPostByPublicId_commPost | CommunityTextPostClipFragment
  }
  serverResponseError?: string
  editorIsOnTopicPage?: boolean
}

export function CommunityPostEditor({
  onClose,
  create,
  update,
  serverResponseError,
  editorIsOnTopicPage,
}: CommunityPostEditorProps) {
  const { createCommPost } = useCreateCommPost()
  const { updateCommPost } = useUpdateCommPost()
  const { data } = useTopicPageQuery()

  if (!create && !update) {
    throw new Error('Post Editor must have a create or update action')
  }
  if (!!create && !!update) {
    throw new Error('Post Editor can only have one action argument at a time')
  }

  const [canSubmitState, setCanSubmitState] = useState<CanSubmitState>({
    isValidBody: true,
    isValidTitle: !!update,
    isTopicSelected: editorIsOnTopicPage || !!update,
  })
  const [submissionData, setSubmissionData] = useState<SubmissionData>({
    title: '',
    topicId: data?.topic && editorIsOnTopicPage ? data.topic.id : '',
    body: update?.commPost?.prosedoc || emptyCommPostProsedoc,
  })

  const [submissionState, setSubmissionState] = useState<
    SubmissionStatus | undefined
  >(undefined)

  const router = useRouter()

  const closeEditor = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    onClose()
  }

  const submitCommunityPost = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    const { topicId, body, title } = submissionData

    const isNotReadyToSubmit = !Object.values(canSubmitState).every(
      (item) => item === true
    )
    if (isNotReadyToSubmit) {
      return
    }
    setSubmissionState(SENDING)

    if (update) {
      const { errorHandler } = update

      try {
        await updateCommPost({
          commPost: update.commPost,
          prosedoc: body,
        })
      } catch (err) {
        Sentry.captureException(err)
        setSubmissionState(undefined)
        return errorHandler(
          'Sorry, our server encountered an error. Try again?'
        )
      }
    }

    if (create) {
      const { errorHandler } = create
      let commPostData
      try {
        commPostData = await createCommPost({
          title: title,
          topicId: topicId,
          prosedoc: body,
        })
      } catch (err) {
        Sentry.captureException(err)
        setSubmissionState(undefined)
        return errorHandler(
          'Sorry, our server encountered an error. Try again?'
        )
      }
      const communityPost =
        commPostData?.data?.createCommunityPost.communityPost
      if (!communityPost) {
        return errorHandler(
          'Sorry, our server encountered an error. Try again?'
        )
      }
      router.push(communityPost.path)
    }
    setSubmissionState(SENT)
    setTimeout(() => {
      setSubmissionState(undefined)
    }, 150)

    setTimeout(() => {
      onClose()
    }, 500)
  }

  const hasServerError = !!serverResponseError

  const isSubmitDisabled = !Object.values(canSubmitState).every(
    (item) => item === true
  )

  return (
    <div>
      <div className={style.topLevelContainer}>
        {hasServerError && (
          <span className={style.serverError}>{serverResponseError}</span>
        )}

        {!editorIsOnTopicPage && (
          <TopicDropdownInput
            disabled={!!update}
            topic={update?.commPost.topic.name}
            setIsTopicSelected={(bool: boolean) =>
              setCanSubmitState({ ...canSubmitState, isTopicSelected: bool })
            }
            setCommunity={(communityId: string) =>
              setSubmissionData({ ...submissionData, topicId: communityId })
            }
          />
        )}

        <PostTitleInput
          value={submissionData.title}
          disabled={!!update}
          postTitle={update?.commPost.title}
          setTitle={(title: string) =>
            setSubmissionData({ ...submissionData, title })
          }
          setIsValidTitle={(bool: boolean) =>
            setCanSubmitState({ ...canSubmitState, isValidTitle: bool })
          }
          hasServerError={hasServerError}
        />
      </div>
      <div id="commPostEditorPortal">
        <PostBodyEditor
          body={submissionData.body}
          setBody={(body: Prosedoc) =>
            setSubmissionData({ ...submissionData, body })
          }
          setIsValidBody={(bool: boolean) =>
            setCanSubmitState({ ...canSubmitState, isValidBody: bool })
          }
        />

        <div className={style.actions}>
          <button className={style.cancel} onClick={closeEditor}>
            Cancel
          </button>
          <button
            disabled={isSubmitDisabled}
            className={cn(
              isSubmitDisabled ? style.disabledSubmitButton : style.submit
            )}
            onClick={submitCommunityPost}
          >
            Submit
          </button>
        </div>
      </div>
      {submissionState && (
        <StatusMessage
          update={!!update}
          status={submissionState}
          onDismiss={() => setSubmissionState(undefined)}
        />
      )}
    </div>
  )
}
