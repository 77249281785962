import { isBrowser } from './browser'

export const readFromLocalStorage = (key: string) => {
  if (!isBrowser()) {
    return null
  }
  try {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  } catch {
    null
  }
}

export const writeToLocalStorage = (key: string, value: any) => {
  if (!isBrowser()) {
    return false
  }
  try {
    localStorage.setItem(key, JSON.stringify(value))
    return true
  } catch {
    return false
  }
}
