import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Link from '../Link'
import style from './index.module.css'
import { ProfileIcon } from 'components/IconSvgs/ProfileIcon'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useUserSettingsCloseRedirect } from 'hooks/useUserSettingsCloseRedirect'
import { Shield } from 'components/IconSvgs/Shield'
import { useFlaggedItemsCount } from 'hooks/useFlaggedItemsCount'

const Wrapper = styled.div``

const ProfileContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`

const ShieldContainer = styled.div<{ revealShield: boolean | undefined }>`
  border: none;
  padding-top: 0.8rem;
  margin-right: -0.5rem;
  position: relative;
  display: ${(props) => (props.revealShield ? '' : 'none')};
`

const ShieldLink = styled(Link)`
  border: none;
  padding: 0;
`

const FlaggedItemsBadge = styled.div<{ hasFlaggedItems: boolean }>`
  position: absolute;
  top: 12px;
  right: 15px;
  padding: 5px 5px;
  border-radius: 50%;
  background: red;
  color: white;
  box-shadow: 0px 0px 11px red;
  display: ${(props) => (props.hasFlaggedItems ? '' : 'none')};
`

export const UserProfileContainer = React.memo(function UserProfileContainer() {
  const { user } = useCurrentUser({ fetchPolicy: 'cache-only' })
  const { storeRedirectUrl } = useUserSettingsCloseRedirect()
  const { flaggedItemsCount } = useFlaggedItemsCount()
  const [hasFlaggedItems, setHasFlaggedItems] = useState<boolean>(false)

  useEffect(() => {
    if (flaggedItemsCount) {
      return setHasFlaggedItems(true)
    }
    return setHasFlaggedItems(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flaggedItemsCount])

  return (
    <Wrapper>
      <ProfileContainer>
        <ShieldContainer revealShield={user?.isModerator}>
          <FlaggedItemsBadge hasFlaggedItems={hasFlaggedItems} />
          <ShieldLink href={`/moderation`}>
            <Shield height={'48'} width={'48'} fill={'none'} />
          </ShieldLink>
        </ShieldContainer>
        <Link
          href={'/settings'}
          className={style.navIconLink}
          aria-label="Profile"
          onClick={() => storeRedirectUrl()}
        >
          <ProfileIcon />
        </Link>
      </ProfileContainer>
    </Wrapper>
  )
})
