import React from 'react'

export function Shield({
  stroke,
  fill,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 34 34"
      fill={fill || 'none'}
      stroke={stroke || 'hsl(12, 66%, 61%)'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
    </svg>
  )
}
