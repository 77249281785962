import { Prosedoc } from 'lib/prosemirror'

export const emptyCommPostProsedoc: Prosedoc = {
  type: 'communityPost',
  version: '0.0.1',
  doc: {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
      },
    ],
  },
}
