import gql from 'graphql-tag'

export const PostClipFragment = gql`
  fragment PostClipFragment on Post {
    clipTitle
    publishedAt
    slug
    path
    excerpt
    featureImage
    isFree
    authorsList {
      name
    }
  }
`
