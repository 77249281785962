import React from 'react'

export const StarIcon = ({
  active,
  addFill,
}: {
  addFill?: boolean
  active: boolean
}) => (
  <svg
    width="25"
    height="24"
    fill={addFill && active ? '#e2856e' : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m13.486 1.545 2.827 5.73a.983.983 0 0 0 .737.536l6.325.922a.979.979 0 0 1 .536 1.67l-4.579 4.464a.975.975 0 0 0-.28.866l1.08 6.307a.978.978 0 0 1-1.428 1.03l-5.641-2.966a.979.979 0 0 0-.91 0L6.496 23.08a.978.978 0 0 1-1.429-1.03l1.08-6.307a.975.975 0 0 0-.28-.866l-4.571-4.468a.979.979 0 0 1 .536-1.67l6.332-.927a.982.982 0 0 0 .737-.535l2.827-5.73a.98.98 0 0 1 1.757 0v0Z"
      stroke={active ? '#e2856e' : '#22223b'}
      strokeWidth={active ? (addFill ? '1' : '2') : '1.5'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
