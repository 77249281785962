import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { FlaggedItemsQuery } from 'types/generated/FlaggedItemsQuery'
import { FLAGGED_ITEMS_QUERY } from 'graphql/queries/FlaggedItemsQuery'
import { useCurrentUser } from './useCurrentUser'

const POLLING_INTERVAL = 10000

export const useFlaggedItemsCount = () => {
  const { user } = useCurrentUser()
  const [
    pollForFlaggedItems,
    { data, loading, stopPolling, startPolling },
  ] = useLazyQuery<FlaggedItemsQuery>(FLAGGED_ITEMS_QUERY, {
    fetchPolicy: 'no-cache',
    pollInterval: POLLING_INTERVAL,
  })

  useEffect(() => {
    if (process.env.DISABLE_LONG_POLLING) {
      return
    }
    if (!user || !user.isModerator) {
      return stopPolling && stopPolling()
    }
    startPolling ? startPolling(POLLING_INTERVAL) : pollForFlaggedItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return { flaggedItemsCount: data?.moderation?.flaggedItemCount, loading }
}
