import { useEffect, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import InfiniteScroll from 'react-infinite-scroll-component'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { NotificationQuery_notificationConnection_nodes } from 'types/generated/NotificationQuery'
import {
  UpdateNotificationStatus as UpdateMutation,
  UpdateNotificationStatusVariables as UpdateMutationArgs,
} from 'types/generated/UpdateNotificationStatus'
import { useNotifications } from 'hooks/useNotifications'
import { UPDATE_NOTIFICATION_STATUS } from 'graphql/mutations/UpdateNotificationStatus'
import { useDebouncer } from 'hooks/useDebouncer'
import { Notification } from './Notification'
import style from './index.module.css'

type Notifications = NotificationQuery_notificationConnection_nodes[]
interface SplitNotifications {
  today: Notifications
  thisWeek: Notifications
  oldest: Notifications
}

const ONE_DAY_IN_MS = 86400000
const ONE_WEEK_IN_MS = 604800000

function notificationSplitter(notifications: Notifications) {
  const now = Date.now()
  const splitNotifications: SplitNotifications = {
    today: [],
    thisWeek: [],
    oldest: [],
  }
  return notifications.reduce((allNotifications, currentNotification) => {
    if (currentNotification.status !== 'deleted') {
      const notificationDate = Date.parse(currentNotification.createdAt)
      const difference = now - notificationDate
      if (difference <= ONE_DAY_IN_MS) {
        allNotifications.today.push(currentNotification)
      } else if (difference <= ONE_WEEK_IN_MS) {
        allNotifications.thisWeek.push(currentNotification)
      } else {
        allNotifications.oldest.push(currentNotification)
      }
    }
    return allNotifications
  }, splitNotifications)
}

export function NotificationInbox({ close }: { close: () => void }) {
  const unreadIdsRef = useRef<string[]>([])
  const toBeDeletedIdsRef = useRef<string[]>([])
  const { notifications, loading, fetchMore, hasNextPage } = useNotifications()
  const [idsToBeMarkDeleted, setIdsToMarkDeleted] = useState<string[]>([])
  const [updateNotificationStatusMutation] = useMutation<
    UpdateMutation,
    UpdateMutationArgs
  >(UPDATE_NOTIFICATION_STATUS)

  unreadIdsRef.current = notifications
    .filter((n) => n.status === 'unread')
    .map((n) => n.id)

  const updateNotificationStatus = (input: UpdateMutationArgs['input']) => {
    return updateNotificationStatusMutation({
      variables: { input },
      errorPolicy: 'ignore',
    })
  }

  const markNotificationsAsDeletedDebouncer = useDebouncer(
    (notificationIds: string[]) => {
      updateNotificationStatus({
        notificationIds,
        newStatus: 'deleted',
      }).then(() => {
        toBeDeletedIdsRef.current = []
        setIdsToMarkDeleted([])
      })
    },
    500
  )

  useEffect(() => {
    return function cleanUpNotificationInbox() {
      // mark notifications as read upon closing
      const idsToBeMarkedRead = unreadIdsRef.current
      if (idsToBeMarkedRead.length) {
        const input = {
          notificationIds: idsToBeMarkedRead,
          newStatus: 'read',
        }
        updateNotificationStatus(input)
      }
      const idsToBeMarkDeleted = toBeDeletedIdsRef.current
      if (idsToBeMarkDeleted.length) {
        const input = {
          notificationIds: idsToBeMarkDeleted,
          newStatus: 'deleted',
        }
        updateNotificationStatus(input)
      }
      close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hideNotification = (id: string) => {
    const newArrayOfNotifications = [...idsToBeMarkDeleted, id]
    toBeDeletedIdsRef.current = newArrayOfNotifications
    setIdsToMarkDeleted(newArrayOfNotifications)
    markNotificationsAsDeletedDebouncer(newArrayOfNotifications)
  }

  const hasVisibleNotifications = !!notifications.filter(
    (n) => !idsToBeMarkDeleted.includes(n.id)
  ).length

  if (!hasVisibleNotifications) {
    return (
      <DialogOverlay onDismiss={close} className={style.overlay}>
        <DialogContent
          aria-label="notification-inbox"
          className={style.content}
        >
          <div className={style.header}>Notifications</div>
          <div className={style.emptyState}>
            {loading ? (
              <h3 className={style.loadingText}>LOADING</h3>
            ) : (
              <h3>No new alerts</h3>
            )}
          </div>
        </DialogContent>
      </DialogOverlay>
    )
  }

  const { today, thisWeek, oldest } = notificationSplitter(notifications)

  const todayNotifications = (
    <>
      <div className={style.timeline}>Today</div>
      {today.map((n) => (
        <Notification
          key={n.id}
          notification={n}
          hideNotification={hideNotification}
        />
      ))}
      <div className={style.border}></div>
    </>
  )

  const thisWeekNotifications = (
    <>
      <div className={style.timeline}>This Week</div>
      {thisWeek.map((n) => (
        <Notification
          key={n.id}
          notification={n}
          hideNotification={hideNotification}
        />
      ))}
      <div className={style.border}></div>
    </>
  )

  const earlierNotifications = (
    <>
      <div className={style.timeline}>Earlier</div>
      {oldest.map((n) => (
        <Notification
          key={n.id}
          notification={n}
          hideNotification={hideNotification}
        />
      ))}
    </>
  )

  return (
    <DialogOverlay onDismiss={close} className={style.overlay}>
      <DialogContent
        aria-label="notification-inbox"
        className={style.content}
        id="infiniteScrollAnchorId"
      >
        <div className={style.header}>Notifications</div>
        <InfiniteScroll
          dataLength={notifications?.length || 0}
          next={fetchMore}
          hasMore={hasNextPage || false}
          loader={
            <div className={style.emptyState}>
              <h3 className={style.loadingText}>LOADING</h3>
            </div>
          }
          scrollableTarget="infiniteScrollAnchorId"
        >
          {!!today.length && todayNotifications}
          {!!thisWeek.length && thisWeekNotifications}
          {!!oldest.length && earlierNotifications}
        </InfiniteScroll>
      </DialogContent>
    </DialogOverlay>
  )
}

NotificationInbox.displayName = 'NotificationInbox'
