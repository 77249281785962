import gql from 'graphql-tag'
import { TopicFragment } from './TopicFragment'
import { UserAuthorFragment } from './UserFragment'

export const CommunityTextPostClipFragment = gql`
  fragment CommunityTextPostClipFragment on CommunityTextPost {
    publicId
    status
    id
    path
    title
    preview
    prosedoc
    createdAt
    editedAt
    score
    upvoteCount
    downvoteCount
    usersVote
    commentCount
    flagCount

    author {
      ...UserAuthorFragment
    }
    topic {
      ...TopicFragment
    }
  }
  ${UserAuthorFragment}
  ${TopicFragment}
`
