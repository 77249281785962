import { useMutation } from '@apollo/client'
import { UPDATE_USER_PROFILE_MUTATION } from 'graphql/mutations/UpdateUserProfile'
import {
  UpdateUserProfile,
  UpdateUserProfileVariables,
} from 'types/generated/UpdateUserProfile'

export const useUpdateUsername = () => {
  const [updateUsername] = useMutation<
    UpdateUserProfile,
    UpdateUserProfileVariables
  >(UPDATE_USER_PROFILE_MUTATION)

  return {
    updateUsername: (username: string) =>
      updateUsername({
        variables: { input: { username } },
      }),
  }
}
