import { ReactNode, useEffect } from 'react'
import X from 'components/IconSvgs/X'
import { ModalPortal } from 'components/Modal'
import style from './index.module.css'

type Props = {
  children?: ReactNode
  onDismiss: () => any
}

ModalPortal

export const DismissibleModal = ({ children, onDismiss }: Props) => {
  //block scrolling and close on outer click
  useEffect(() => {
    const handleClick = () => {
      onDismiss()
    }
    window.addEventListener('click', handleClick)
    // document.body.style.overflow = 'hidden'
    // document.body.style.position = 'relative'
    return () => {
      // document.body.style.overflow = ''
      // document.body.style.position = ''
      window.removeEventListener('click', handleClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalPortal>
      {/* eslint-disable-next-line */}
      <div className={style.container} onClick={(e) => e.stopPropagation()}>
        <div className={style.header}>
          <button
            className={style.dismissBtn}
            aria-label="dismiss modal"
            onClick={(e) => {
              e.stopPropagation()
              onDismiss()
            }}
          >
            <X stroke={'#e2856e'} fill={'#e2856e'} />
          </button>
        </div>
        <div className={style.childWrapper}>{children}</div>
      </div>
    </ModalPortal>
  )
}
