// BEGIN CSS IMPORTS
import '@fontsource/lato/400.css'
import '@fontsource/lato/400-italic.css'
import '@fontsource/lato/700.css'
import '@fontsource/lato/700-italic.css'
import '@fontsource/domine/400.css'
import '@fontsource/domine/700.css'
import '@fontsource/playfair-display/600.css'
import '@fontsource/playfair-display/600-italic.css'
import '@reach/dialog/styles.css'
import '../css/variables.css'
import './_app.css'
// END CSS IMPORTS
import { createContext, useState, ReactElement } from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { ErrorBoundary } from 'react-error-boundary'
import { useApollo } from '../lib/apolloClient'
import { Header } from '../components/Header'
import Footer from '../components/Footer'
import MobileFixedBottomNav from '../components/MobileFixedBottomNav'
import { NotSignedInModal } from 'components/GlobalModals/NotSignedInModal'
import { BetaFeatureModal } from 'components/GlobalModals/BetaFeatureModal'
import { init } from '../lib/sentry'
import { ErrorFallback } from 'components/ErrorFallback'

init()

export interface GlobalModalContextProps {
  isModalOpen: boolean
  toggleModalOpen: () => void
  isBetaWallOpen: boolean
  toggleBetaWallOpen: () => void
}

export const GlobalModalContext = createContext<
  Partial<GlobalModalContextProps>
>({})
GlobalModalContext.displayName = 'GlobalModalContext'

export function ModalOpenProvider({
  children,
}: {
  children: ReactElement[] | ReactElement
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isBetaWallOpen, setIsBetaWallOpen] = useState(false)

  return (
    <GlobalModalContext.Provider
      value={{
        isModalOpen,
        toggleModalOpen: () => setIsModalOpen(!isModalOpen),
        isBetaWallOpen,
        toggleBetaWallOpen: () => setIsBetaWallOpen(!isBetaWallOpen),
      }}
    >
      {children}
    </GlobalModalContext.Provider>
  )
}

export default function App({ Component, pageProps = {} }: AppProps) {
  const { initialApolloState } = pageProps
  const apolloClient = useApollo(initialApolloState)
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
      </Head>

      <ApolloProvider client={apolloClient}>
        <ModalOpenProvider>
          <NotSignedInModal />
          <BetaFeatureModal />
          <Header />
          <MobileFixedBottomNav />

          <main style={{ zIndex: 0 }}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Component {...pageProps} />
            </ErrorBoundary>

            <Footer />
          </main>
        </ModalOpenProvider>
      </ApolloProvider>
    </>
  )
}
