import React, { useEffect, useRef } from 'react'
import { keymap } from 'prosemirror-keymap'
import { baseKeymap } from 'prosemirror-commands'
import { Schema, Slice, Fragment, Node } from 'prosemirror-model'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { Plugin } from 'prosemirror-state'
import { useProseMirror, ProseMirror } from 'use-prosemirror'
import { Prosedoc } from 'lib/prosemirror'
import style from './index.module.css'

function placeHolderPluginFactory(
  placeholder = 'Tell us how you really feel...'
) {
  // taken from https://discuss.prosemirror.net/t/how-to-input-like-placeholder-behavior/705/16
  return new Plugin({
    props: {
      decorations: (state) => {
        const decorations: Decoration<{ [key: string]: any }>[] = []

        const decorate = (
          node: { type: { isBlock: any }; childCount: number; nodeSize: any },
          pos: number
        ) => {
          if (node.type.isBlock && node.childCount === 0) {
            decorations.push(
              Decoration.node(pos, pos + node.nodeSize, {
                class: 'empty-node',
                'data-placeholder': placeholder,
              })
            )
          }
        }

        state.doc.descendants(decorate)

        return DecorationSet.create(state.doc, decorations)
      },
    },
  })
}

const linkPlugin = new Plugin({
  props: {
    transformPasted: (slice: Slice) => {
      return new Slice(linkify(slice.content), slice.openStart, slice.openEnd)
    },
  },
})

// regex taken from here https://github.com/ueberdosis/tiptap/blob/01ec8b2bf4c3ac0fb6a31992656f5c8d4ed0f25e/packages/tiptap-extensions/src/marks/Link.js#L59
const HTTP_LINK_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi
// code taken from here https://github.com/ProseMirror/prosemirror/issues/90#issuecomment-284593797
const linkify = function (fragment: Fragment): Fragment {
  const linkified: Node[] = []
  fragment.forEach(function (child: Node) {
    if (child.isText) {
      const text = child.text as string
      let pos = 0
      let match

      while ((match = HTTP_LINK_REGEX.exec(text))) {
        const start = match.index
        const end = start + match[0].length
        const link = child.type.schema.marks['link']

        // simply copy across the text from before the match
        if (start > 0) {
          linkified.push(child.cut(pos, start))
        }

        const urlText = text.slice(start, end)
        linkified.push(
          child
            .cut(start, end)
            .mark(link.create({ href: urlText }).addToSet(child.marks))
        )
        pos = end
      }

      // copy over whatever is left
      if (pos < text.length) {
        linkified.push(child.cut(pos))
      }
    } else {
      linkified.push(child.copy(linkify(child.content)))
    }
  })

  return Fragment.fromArray(linkified)
}

// basic schema ripped from https://prosemirror.net/examples/schema/ AND
// https://github.com/ProseMirror/prosemirror-schema-basic/blob/eb309ffebf3477852c7ed759d7bfa758bb595bd0/src/schema-basic.js
const schema = new Schema({
  marks: {
    link: {
      attrs: {
        href: {},
        title: { default: null },
      },
      inclusive: false,
      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs(dom) {
            return {
              // TODO fix these lines, but the code works so
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              href: dom.getAttribute('href'),
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              title: dom.getAttribute('title'),
            }
          },
        },
      ],
      toDOM(node) {
        const { href, title } = node.attrs
        return ['a', { href, title }, 0]
      },
    },
  },
  nodes: {
    text: {
      group: 'inline',
    },
    doc: {
      content: 'block+',
    },
    paragraph: {
      content: 'inline*',
      group: 'block',
      parseDOM: [{ tag: 'p' }],
      toDOM() {
        return ['p', 0]
      },
    },
  },
})

interface DefaultProsedocMetaData {
  version: Prosedoc['version']
  type: Prosedoc['type']
}

// see https://codesandbox.io/s/use-prosemirror-basic-example-nie7f?file=/src/App.tsx:212-256
export function ProseMirrorEditor({
  prosedoc,
  placeholder = 'Tell us how you really feel...',
  defaultProsedocMetaData,
  onChange,
  focusOnRender,
}: {
  prosedoc?: Prosedoc
  placeholder?: string
  defaultProsedocMetaData: DefaultProsedocMetaData
  onChange: (prosedoc: Prosedoc) => any
  focusOnRender?: boolean
}) {
  const [state, setState] = useProseMirror({
    schema,
    plugins: [
      keymap(baseKeymap),
      placeHolderPluginFactory(placeholder),
      linkPlugin,
    ],
    ...(prosedoc ? { doc: schema.nodeFromJSON(prosedoc.doc) } : {}),
  })

  const { type, version } = prosedoc ? prosedoc : defaultProsedocMetaData

  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (
      focusOnRender &&
      inputRef &&
      inputRef.current &&
      inputRef.current.view
    ) {
      inputRef.current.view.focus()
    }
  }, [inputRef, focusOnRender])

  return (
    <>
      <ProseMirror
        ref={inputRef && inputRef}
        className={style.input}
        state={state}
        onChange={(state) => {
          setState(state)
          onChange({
            type,
            version,
            doc: state.doc.toJSON() as Prosedoc['doc'],
          })
        }}
      />
    </>
  )
}
