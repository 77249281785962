import { isBrowser } from '../browser'

const DEFAULT_URL = 'https://thedipp.com'

const getBaseUrl = () =>
  // TODO this should be configured via a config file
  isBrowser() ? window.location.origin : DEFAULT_URL

const normalizePath = (fullPath: string) => {
  if (fullPath.indexOf('#') === 0) {
    return fullPath
  }
  const [path, queryString] = fullPath.split('?')
  const normalizedPath = `/${path}`
    .replace(/\/+/g, '/') // remove multiple slashes
    .replace(/(\w)\/#/, '$1#') // remove a trailing slash before a #
    .replace(/(\w)\/$/, '$1') // remove a trailing slash
  return queryString ? `${normalizedPath}?${queryString}` : normalizedPath
}

export const normalizePathOrUrl = (pathOrUrl: string) => {
  try {
    const url = new URL(pathOrUrl)
    url.pathname = normalizePath(url.pathname)
    return url.toString()
  } catch (e) {
    return normalizePath(pathOrUrl)
  }
}

type pathTypes = string | null | number | undefined

export const joinPaths = (...pathParts: (pathTypes | pathTypes[])[]) =>
  normalizePath(
    pathParts
      .flat()
      .filter((p) => p)
      .join('/')
  )

export const splitPath = (path: string) => path.split('/').filter((str) => str)

export const sanitizePath = (path: string) =>
  normalizePath(path.split('?')[0].split('#')[0])

export const getCanonicalPostPath = (path: string | undefined) =>
  // added conditional to pass test in post meta tags where router wasn't set up
  path ? sanitizePath(path).replace('/isr/', '/').replace('/google/', '/') : ''

export const isOnCommentsPath = (path: string) =>
  splitPath(sanitizePath(path)).includes('comments')

const createUrl = (path: string, base: string) =>
  new URL(normalizePath(path), base).toString()

export const createDippUrl = (path?: string) =>
  createUrl(path || '', getBaseUrl())

export const createRedirectPath = (path: string, redirectPath?: string) => {
  const defaultRedirectPath = isBrowser() ? window.location.pathname : '/'
  const url = new URL(normalizePath(path), getBaseUrl())
  url.searchParams.append(
    'redirect',
    normalizePath(redirectPath || defaultRedirectPath)
  )
  return url.pathname + url.search
}
