import cn from 'classnames'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { useGlobalModalContext } from 'hooks/useGlobalModalContext'
import style from './index.module.css'
import Link from 'components/Link'

export function BetaFeatureModal() {
  const { isBetaWallOpen, toggleBetaWallOpen } = useGlobalModalContext()

  function handleSignUp() {
    localStorage.setItem('dipp-subscription-allowed', 'true')
    return toggleBetaWallOpen()
  }

  return (
    <DialogOverlay
      isOpen={isBetaWallOpen}
      style={{ zIndex: 500 }}
      onDismiss={toggleBetaWallOpen}
    >
      <DialogContent
        aria-label="not-signed-in-modal"
        style={{
          zIndex: 500,
          padding: '0',
          width: 'auto',
          background: 'transparent',
        }}
      >
        <div className={style.wrapper}>
          <div className={style.header}>Sorry!</div>
          <span className={style.text}>
            Only subscribers or users with beta access can post!
          </span>
          <span className={cn(style.text, style.textEnd)}>
            Reach out to <b>hi@thedipp.com</b> if you want access!
          </span>
          <div className={style.buttonRow}>
            {' '}
            <Link className={style.buttonLink} href="mailto:hi@thedipp.com">
              <button
                aria-label="Email the dipp"
                className={style.secondaryButton}
                onClick={() => handleSignUp()}
              >
                Say Hello
              </button>
            </Link>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}
