import cn from 'classnames'
import { HomeIcon } from 'components/IconSvgs/HomeIcon'
import { StarIcon } from 'components/IconSvgs/StarIcon'
import { SearchIcon } from 'components/IconSvgs/SearchIcon'
import { NotificationBellIcon } from 'components/IconSvgs/NotificationBellIcon'
import { NotificationInbox } from 'components/NotificationSystem/NotificationInbox'
import { useNavButtonState } from 'hooks/useNavButtonState'
import Link from 'components/Link'
import style from './index.module.css'
import { CommPostEditorView } from 'components/CommPostEditorView'
import EncircledPlusSign from 'components/IconSvgs/EncircledPlusSign'

export default function MobileFixedBottomNav() {
  const {
    user,
    isHomeActive,
    isDiscoverActive,
    isFavoritesActive,
    isNotificationsActive,
    isEditorActive,
    hasNewNotifications,
    openNotifications,
    closeNotifications,
    toggleEditor,
    toggleNotLoggedInModal,
  } = useNavButtonState()

  return (
    <>
      <nav className={style.mobileBottomContainer}>
        <div className={style.navIconContainer}></div>
        <div className={style.navIconContainer}>
          <Link href={'/'} className={style.navIcon} aria-label="Home">
            <HomeIcon active={isHomeActive} />
          </Link>
          <Link
            href={'/discover'}
            className={style.navIcon}
            aria-label="Discover"
          >
            <SearchIcon active={isDiscoverActive} />
          </Link>

          <>
            {user ? (
              <>
                <button
                  aria-label="Create Community Post"
                  className={cn(
                    style.navIcon,
                    style.notificationIcon,
                    style.plusSignIcon,
                    isEditorActive && style.plusSignIconActive
                  )}
                  onClick={toggleEditor}
                >
                  <EncircledPlusSign active={isEditorActive}>
                    {isEditorActive && (
                      <CommPostEditorView
                        showing={isEditorActive}
                        close={toggleEditor}
                      />
                    )}
                  </EncircledPlusSign>
                </button>
                <Link
                  href={'/favorites'}
                  className={style.navIcon}
                  aria-label="Favorites"
                >
                  <StarIcon active={isFavoritesActive} />
                </Link>
                <button
                  aria-label="Notification"
                  className={cn(style.navIcon, style.notificationIcon)}
                  onClick={
                    isNotificationsActive
                      ? closeNotifications
                      : openNotifications
                  }
                >
                  <NotificationBellIcon active={isNotificationsActive}>
                    <div
                      className={cn(
                        isNotificationsActive
                          ? style.pointer
                          : style.hiddenPointer
                      )}
                    />
                    <div
                      className={cn(
                        hasNewNotifications ? style.badge : style.hiddenBadge
                      )}
                    ></div>
                    {isNotificationsActive && (
                      <NotificationInbox close={closeNotifications} />
                    )}
                  </NotificationBellIcon>
                </button>
              </>
            ) : (
              <>
                <button
                  aria-label="Create Community Post"
                  className={cn(
                    style.navIcon,
                    style.notificationIcon,
                    style.plusSignIcon,
                    isEditorActive && style.plusSignIconActive
                  )}
                  onClick={toggleNotLoggedInModal}
                >
                  <EncircledPlusSign active={isEditorActive}>
                    {isEditorActive && (
                      <CommPostEditorView
                        showing={isEditorActive}
                        close={toggleEditor}
                      />
                    )}
                  </EncircledPlusSign>
                </button>
                <button
                  className={style.navIcon}
                  aria-label="Favorites"
                  onClick={toggleNotLoggedInModal}
                >
                  <StarIcon active={isFavoritesActive} />
                </button>
                <button
                  aria-label="Notification"
                  className={cn(style.navIcon, style.notificationIcon)}
                  onClick={toggleNotLoggedInModal}
                >
                  <NotificationBellIcon
                    active={
                      isNotificationsActive ? isNotificationsActive : false
                    }
                  ></NotificationBellIcon>
                </button>
              </>
            )}
          </>
        </div>
      </nav>
    </>
  )
}
