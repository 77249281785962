import { createContext, ReactElement, useState } from 'react'
import { CommunityPostEditor } from '../CommunityPostEditor'
import style from './index.module.css'
import { CantPostModal } from '../CantPostModal'
import { useCantPostModalContext } from '../useCantPostModalContext'
import { useCommPostPermissions } from '../useCommPostPermissions'
import { CompleteUsernameModal } from 'components/CommentSystem/CommunityPostComment/CompleteUsernameModal'
import { useCommentsPermissions } from 'components/CommentSystem/shared/useCommentsPermissions'

export interface CantPostModalContextProps {
  isModalOpen: boolean
  toggleModalOpen: () => void
}

export const CantPostModalContext = createContext<
  Partial<CantPostModalContextProps>
>({})
CantPostModalContext.displayName = 'ModalOpenContext'

export function CantPostModalProvider({
  children,
}: {
  children: ReactElement[] | ReactElement
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <CantPostModalContext.Provider
      value={{
        isModalOpen,
        toggleModalOpen: () => setIsModalOpen(!isModalOpen),
      }}
    >
      {children}
    </CantPostModalContext.Provider>
  )
}

function OpenInput({
  setInputOpen,
  setServerResponseError,
  serverResponseError,
}: {
  setInputOpen: React.Dispatch<React.SetStateAction<boolean>>
  setServerResponseError: React.Dispatch<React.SetStateAction<string>>
  serverResponseError: string
}) {
  const permissions = useCommentsPermissions()

  if (!permissions.canComment) {
    return (
      <>
        <CompleteUsernameModal onDismiss={() => setInputOpen(false)} />
        <ClosedInput
          serverResponseError={serverResponseError}
          setInputOpen={setInputOpen}
        />
      </>
    )
  }
  return (
    <CommunityPostEditor
      onClose={() => setInputOpen(false)}
      create={{
        errorHandler: setServerResponseError,
      }}
      editorIsOnTopicPage
    />
  )
}

function ClosedInput({
  setInputOpen,
  serverResponseError,
}: {
  setInputOpen: React.Dispatch<React.SetStateAction<boolean>>
  serverResponseError: string
}) {
  const hasServerError = !!serverResponseError
  const { canPost } = useCommPostPermissions()
  const { toggleModalOpen } = useCantPostModalContext()

  function handleButtonClick() {
    if (canPost) {
      return setInputOpen(true)
    }
    return toggleModalOpen()
  }

  return (
    <div className={style.closedInputRow}>
      <CantPostModal />
      {hasServerError && (
        <span className={style.serverResponseError}>{serverResponseError}</span>
      )}

      <button
        tabIndex={0}
        className={style.closedInput}
        onClick={() => handleButtonClick()}
      >
        <span className={style.inputPrompt}>Post to this community</span>
      </button>

      <button onClick={() => handleButtonClick()} className={style.replyButton}>
        POST
      </button>
    </div>
  )
}

export function CommunityPostInput() {
  const [inputOpen, setInputOpen] = useState(false)
  const [serverResponseError, setServerResponseError] = useState('')

  return (
    <CantPostModalProvider>
      <div className={style.wrapper}>
        <div className={style.inputContainer}>
          {inputOpen ? (
            <OpenInput
              serverResponseError={serverResponseError}
              setServerResponseError={setServerResponseError}
              setInputOpen={setInputOpen}
            />
          ) : (
            <ClosedInput
              serverResponseError={serverResponseError}
              setInputOpen={setInputOpen}
            />
          )}
        </div>
      </div>
    </CantPostModalProvider>
  )
}
