import gql from 'graphql-tag'

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      user {
        publicId
        email
        username
      }
    }
  }
`
