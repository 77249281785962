// ----------------------------------------------------------------------
// TAKEN FROM https://github.com/vercel/next.js/issues/8207#issuecomment-664975591
// Smart wrapper around Next.js <Link>
//
// This is to improve the default handling of Next.js dynamic links which
// requires both `href` and `as` props.
// This improvement enables the following usage:
//
// <Link page="/users/[id]" params={{ id: user.id }}>{user.name}</Link>
//
// <Link page="/blog/[...slug]" params={{ slug: ['coffee', 'frenchpress'] }}>View Here</Link>

import cn from 'classnames'
import { useRouter } from 'next/router'
import { ReactNode, ComponentProps } from 'react'
import { ClassValue } from 'classnames/types'
import { normalizePathOrUrl } from 'lib/urls'

// ----------------------------------------------------------------------

export type LinkProps = {
  // TODO require `page` OR `href`
  href?: string
  as?: string
  page?: string

  children: ReactNode
  className?: ClassValue
  activeClassName?: string
  partiallyActive?: boolean

  params?: Record<string, number | number[] | string | string[]>
} & ComponentProps<'a'>

export default function Link({
  href,
  as,
  page,
  children,
  className,
  activeClassName,
  partiallyActive = false,
  params,
  ...props
}: LinkProps) {
  const { pathname } = useRouter()

  // If user suppiled href & as, then use those. Otherwise fallback to smart `page` logic
  const finalHref = href || page || ''
  let finalAs = as || page || ''

  if (page && params) {
    // We treat the `page` prop as a template for generating the `as` prop
    for (const [key, value] of Object.entries(params)) {
      if (
        finalAs.includes(`[${key}]`) &&
        (typeof value === 'string' || typeof value === 'number')
      ) {
        finalAs = finalAs.replace(`[${key}]`, value.toString())
      } else if (finalAs.includes(`[[...${key}]]`)) {
        const normalizedValue = Array.isArray(value)
          ? value.join('/')
          : value.toString()
        finalAs = finalAs.replace(`[[...${key}]]`, normalizedValue)
      } else if (finalAs.includes(`[...${key}]`)) {
        const normalizedValue = Array.isArray(value)
          ? value.join('/')
          : value.toString()
        finalAs = finalAs.replace(`[...${key}]`, normalizedValue)
      }
    }
  }

  const destPath = finalAs || finalHref || ''
  const isActive = partiallyActive
    ? pathname.startsWith(destPath)
    : pathname === destPath

  const classNames = [className, isActive && activeClassName].filter((c) => c)
  const targetHref = normalizePathOrUrl(
    finalHref.includes('[') && finalAs ? finalAs : finalHref
  )

  return (
    <a className={cn(classNames)} {...props} href={targetHref}>
      {children}
    </a>
  )
}
