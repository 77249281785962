import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client'

interface QueryData {
  [key: string]: any
}

interface InfiniteQueryOptions<TData, TVariables>
  extends QueryHookOptions<TData, TVariables> {
  connectionKey?: string
}

const extractCustomReturnFieldsFromData = (
  data: QueryData | null | undefined,
  connectionKey: string
): { dataLength: number; hasNextPage: boolean; endCursor: string | null } => {
  if (!data || !data[connectionKey]) {
    return { dataLength: 0, hasNextPage: false, endCursor: null }
  }
  const { pageInfo, nodes, edges } = data[connectionKey]
  const { hasNextPage = false, endCursor = null } = pageInfo || {}
  const dataLength = nodes?.length || edges?.length || 0

  return { hasNextPage, endCursor, dataLength }
}

function mergeFetchedQueries<TData extends QueryData>({
  prevQuery,
  fetchMoreResult,
  connectionKey,
}: {
  prevQuery: TData
  fetchMoreResult: TData | undefined
  connectionKey: string
}) {
  if (!fetchMoreResult) {
    return prevQuery
  }
  if ((!prevQuery || !prevQuery[connectionKey]) && fetchMoreResult) {
    return fetchMoreResult
  }
  const { __typename, pageInfo, nodes, edges } = prevQuery[connectionKey]
  const newConnectionResults = fetchMoreResult[connectionKey]
  const {
    pageInfo: { hasNextPage, endCursor },
  } = newConnectionResults

  return {
    [connectionKey]: {
      __typename: __typename,
      pageInfo: { ...pageInfo, hasNextPage, endCursor },
      ...(!!edges && { edges: [...edges, ...newConnectionResults?.edges] }),
      ...(!!nodes && { nodes: [...nodes, ...newConnectionResults?.nodes] }),
    },
  } as TData
}

export function useInfiniteQuery<
  TData extends QueryData,
  TVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  {
    connectionKey = 'connection',
    ...apolloOptions
  }: InfiniteQueryOptions<TData, TVariables>
) {
  const { data, loading, error, fetchMore } = useQuery<TData, TVariables>(
    query,
    apolloOptions
  )

  if (data && !data[connectionKey]) {
    throw new Error(
      `connectionKey: ${connectionKey} provided does not exist on query response`
    )
  }

  function fetchMoreFromConnection() {
    fetchMore<TData, OperationVariables>({
      variables: {
        after: data && data[connectionKey].pageInfo.endCursor,
      },
      updateQuery: (prevQuery, { fetchMoreResult }) =>
        mergeFetchedQueries({ prevQuery, fetchMoreResult, connectionKey }),
    })
  }

  return {
    ...extractCustomReturnFieldsFromData(data, connectionKey),
    data,
    loading,
    error,
    fetchMore: fetchMoreFromConnection,
  }
}
