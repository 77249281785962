import cn from 'classnames'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import style from './index.module.css'
import { useCantPostModalContext } from '../useCantPostModalContext'
import Link from 'components/Link'

export function CantPostModal({ onDismiss }: { onDismiss?: () => void }) {
  const { isModalOpen, toggleModalOpen } = useCantPostModalContext()
  const onDismissWrapper = () => (onDismiss ? onDismiss() : toggleModalOpen())
  return (
    <DialogOverlay
      isOpen={isModalOpen}
      style={{ zIndex: 500 }}
      onDismiss={onDismissWrapper}
    >
      <DialogContent
        aria-label="not-signed-in-modal"
        style={{
          zIndex: 500,
          padding: '0',
          width: 'auto',
          background: 'transparent',
        }}
      >
        <div className={style.wrapper}>
          <div className={style.header}>Sorry!</div>
          <span className={style.text}>
            Only users who subscribe or have beta access can currently post
          </span>
          <span className={style.text}>
            Reach out to <b>hi@thedipp.com</b> if you want access!
          </span>
          <span className={cn(style.text, style.textEnd)}>
            Have something to say? <Link href="/subscribe">sign up</Link> to The
            Dipp!
          </span>
          <div className={style.buttonRow}>
            {' '}
            <button
              aria-label="Dismiss"
              className={style.secondaryButton}
              onClick={onDismissWrapper}
            >
              No Thanks
            </button>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}
