export default function EncircledPlusSign({
  active,
  children,
}: {
  children?: React.ReactNode
  active?: boolean
}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="26"
        viewBox="0 0 15 14"
        fill={active ? '#dd745a' : '#22223b'}
      >
        <path
          strokeWidth=".1"
          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
        />
        <path
          strokeWidth=".1"
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
        />
      </svg>

      {children}
    </>
  )
}
