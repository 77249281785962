import ReactDOM from 'react-dom'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  elementId?: string
}

export const ModalPortal = ({ children, elementId = 'modal' }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return ReactDOM.createPortal(children, document.getElementById(elementId)!)
}
