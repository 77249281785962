import gql from 'graphql-tag'

export const CURRENT_USER_NOTIFICATION_COUNT_QUERY = gql`
  query CurrentUserNotificationQuery {
    currentUser {
      publicId
      notificationCount
    }
  }
`
