import { useContext } from 'react'
import { GlobalModalContext, GlobalModalContextProps } from 'pages/_app'

export function useGlobalModalContext() {
  const context = useContext(GlobalModalContext)
  if (context === undefined) {
    throw new Error(
      `useModalOpenContext must be used within a ModalOpenContextProvider`
    )
  }
  return context as GlobalModalContextProps
}
