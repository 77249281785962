import gql from 'graphql-tag'

export const TopicFragment = gql`
  fragment TopicFragment on Topic {
    __typename
    id
    name
    slug
    path
    updatedAt
    description
    featureImage
  }
`
