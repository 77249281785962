import gql from 'graphql-tag'
import { TopicFragment } from './TopicFragment'

export const PrivateUserFragment = gql`
  fragment PrivateUserFragment on PrivateUser {
    cognitoIdentityType
    avatarProps {
      gradientColorStart
      gradientColorEnd
    }
    publicId
    path
    email
    username
    userRole
    firstName
    lastName
    bio
    dateOfBirth
    isSubscribed
    isModerator
    hasPickedTopics
    notificationCount
    genresList {
      ...TopicFragment
    }
    seriesList {
      ...TopicFragment
    }
    networksList {
      ...TopicFragment
    }
    topicsList {
      ...TopicFragment
    }
  }
  ${TopicFragment}
`
export const UserAuthorFragment = gql`
  fragment UserAuthorFragment on PublicUser {
    publicId
    username
    avatarProps {
      gradientColorStart
      gradientColorEnd
    }
    path
  }
`
