import React from 'react'
import cn from 'classnames'
import Link from 'components/Link'
import { HomeIcon } from 'components/IconSvgs/HomeIcon'
import { StarIcon } from 'components/IconSvgs/StarIcon'
import { SearchIcon } from 'components/IconSvgs/SearchIcon'
import { NotificationBellIcon } from 'components/IconSvgs/NotificationBellIcon'
import EncircledPlusSign from 'components/IconSvgs/EncircledPlusSign'
import { NotificationInbox } from 'components/NotificationSystem/NotificationInbox'
import { CommPostEditorView } from 'components/CommPostEditorView'
import { useNavButtonState } from 'hooks/useNavButtonState'
import style from '../index.module.css'

export const NavButtons = React.memo(function NavButtons() {
  const {
    user,
    isHomeActive,
    isDiscoverActive,
    isFavoritesActive,
    isNotificationsActive,
    isEditorActive,
    hasNewNotifications,
    openNotifications,
    closeNotifications,
    toggleEditor,
    toggleNotLoggedInModal,
  } = useNavButtonState()

  return (
    <div className={style.navIconContainer}>
      <Link href={'/'} className={style.navIconLink} aria-label="Home">
        <HomeIcon active={isHomeActive} />
      </Link>
      <Link
        href={'/discover'}
        className={style.navIconLink}
        aria-label="Discover"
      >
        <SearchIcon active={isDiscoverActive} />
      </Link>
      {user ? (
        <>
          <button
            aria-label="Create Community Post"
            className={cn(
              style.navIcon,
              style.notificationIcon,
              style.plusSignIcon,
              isEditorActive && style.plusSignIconActive
            )}
            onClick={toggleEditor}
          >
            <EncircledPlusSign active={isEditorActive}>
              {isEditorActive && (
                <CommPostEditorView
                  showing={isEditorActive}
                  close={toggleEditor}
                />
              )}
            </EncircledPlusSign>
          </button>
          <Link
            href={'/favorites'}
            className={style.navIconLink}
            aria-label="Favorites"
          >
            <StarIcon active={isFavoritesActive} />
          </Link>
          <button
            aria-label="Notifications"
            className={cn(style.navIcon, style.notificationIcon)}
            onClick={openNotifications}
          >
            <NotificationBellIcon active={isNotificationsActive}>
              <div
                className={
                  isNotificationsActive ? style.pointer : style.hiddenPointer
                }
              />
              <div
                className={
                  hasNewNotifications ? style.badge : style.hiddenBadge
                }
              ></div>
              {isNotificationsActive && (
                <NotificationInbox close={closeNotifications} />
              )}
            </NotificationBellIcon>
          </button>
        </>
      ) : (
        <>
          <button
            aria-label="Create Community Post"
            className={cn(
              style.navIcon,
              style.notificationIcon,
              style.plusSignIcon,
              isEditorActive && style.plusSignIconActive
            )}
            onClick={toggleNotLoggedInModal}
          >
            <EncircledPlusSign active={isEditorActive}>
              {isEditorActive && (
                <CommPostEditorView
                  showing={isEditorActive}
                  close={toggleEditor}
                />
              )}
            </EncircledPlusSign>
          </button>
          <button
            aria-label="Favorites"
            className={style.navIcon}
            onClick={toggleNotLoggedInModal}
          >
            <div className={style.navIconLink}>
              <StarIcon active={isFavoritesActive && !isNotificationsActive} />
            </div>
          </button>
          <button
            aria-label="Notifications"
            className={cn(style.navIcon, style.notificationIcon)}
            onClick={toggleNotLoggedInModal}
          >
            <NotificationBellIcon
              active={isNotificationsActive ? isNotificationsActive : false}
            ></NotificationBellIcon>
          </button>
        </>
      )}
    </div>
  )
})
