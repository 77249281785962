import { useMemo } from 'react'
import { useCurrentUser } from 'hooks/useCurrentUser'

export const useCommentsPermissions = () => {
  const { user } = useCurrentUser()

  const isLoggedIn = !!user

  return useMemo(
    () => ({
      canComment: !!(user?.isSubscribed && user?.username),
      isSubscribed: !!user?.isSubscribed,
      canPost: !!(user?.isSubscribed && user?.username),
      vote: isLoggedIn,
      reply: isLoggedIn,
      flag: isLoggedIn,
      loadMore: isLoggedIn,
    }),
    [isLoggedIn, user]
  )
}
