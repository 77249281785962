import React from 'react'

export function NotificationBellIcon({
  active,
  children,
}: {
  children?: any
  active: boolean
}) {
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width="25"
        height="23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.23.857c.985 0 1.96.19 2.87.56.91.369 1.737.91 2.433 1.592a7.34 7.34 0 0 1 1.626 2.384c.377.891.571 1.847.571 2.812 0 8.175 3.048 9.795 3.874 9.795H.855c.832 0 3.875-1.628 3.875-9.795 0-1.949.79-3.818 2.196-5.196A7.58 7.58 0 0 1 12.23.857v0ZM8.27 18.613a4.112 4.112 0 0 0 1.517 2.235c.749.562 1.67.867 2.619.867.948 0 1.87-.305 2.619-.867a4.112 4.112 0 0 0 1.517-2.235"
          stroke={active ? '#e2856e' : '#22223b'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      {children}
    </div>
  )
}
