import { TopicSearchQuery_topicSearchConnection_nodes } from 'types/generated/TopicSearchQuery'
import style from './index.module.css'

export function TopicDropDownSelection({
  possibleTopics,
  setCommunity,
}: {
  possibleTopics: TopicSearchQuery_topicSearchConnection_nodes[]
  setCommunity: (
    community: TopicSearchQuery_topicSearchConnection_nodes
  ) => void
}) {
  return (
    <div className={style.container}>
      {possibleTopics.map(
        (topic: TopicSearchQuery_topicSearchConnection_nodes) => {
          return (
            <button
              className={style.topicSelectionButton}
              key={topic.name}
              onClick={() => setCommunity(topic)}
            >
              {topic.name}
            </button>
          )
        }
      )}
    </div>
  )
}
